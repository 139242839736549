.hero-slider {
    white-space: pre-wrap;
    height: 70vh;
    max-height: 1080px;
    overflow: hidden;
    position: relative;
    margin-bottom: 50px;
    margin-top: calc(#{$header-height} * -1);

    @include tablet {
        margin-top: calc(#{$header-tablet-height} * -1);
    }

    @include mobile {
        margin-top: calc(#{$header-mobile-height} * -1);
    }

    &__item {
        @include flex(center, space-between);

        opacity: 0;
        visibility: hidden;
        pointer-events: none;
        position: absolute;
        inset: 0;

        @include tablet {
            flex-direction: column-reverse;
            padding-top: $header-tablet-height;
        }

        @include mobile {
            padding-top: unset;
            padding-bottom: 40px;
        }

        &.active {
            opacity: 1;
            visibility: visible;
            pointer-events: visible;
        }

        &__info {
            width: 58.33%;

            @include tablet {
                width: 100%;
                flex-grow: 1;
            }

            &__title,
            &__description {
                margin-bottom: 60px;

                @include tablet {
                    margin-bottom: 15px;
                }
            }

            &__title {
                // font-family: "NanumSquareR, SCDream2, laundrygothic_Regular", "BMJUA_ttf", "M PLUS Rounded 1c", "sans-serif", ;
                font-family: "SUIT-Regular";
                font-weight:normal;
                font-style:normal;
                font-size: 4.5rem;
                line-height: 6.75rem;
                letter-spacing: -0.045em;
                overflow: hidden;

                @include mobile {
                    font-size: 2.5rem;
                    line-height: 3.75rem;
                }
            }

            &__description {
                color: $txt-second-color;
                font-size: 1.5rem;
                line-height: 2.25rem;
                overflow: hidden;

                @include mobile {
                    font-size: 1rem;
                    line-height: 1.5rem;
                }
            }

            &__btn {
                overflow: hidden;
            }

            &__title > span,
            &__description > span,
            &__btn button {
                display: block;
                transform: translateY(-100%);
                transition: transform 0.5s ease;
            }
        }

        &.active &__info {
            &__title > span,
            &__description > span,
            &__btn button {
                transform: translateY(0);
            }
        }

        &__image {
            flex-grow: 1;
            // flex-grow: 0;
            position: relative;
            height: 100%;
            z-index: 99;
            pointer-events: none;

            @include tablet {
                width: 100%;
                height: 60%;
                @include flex(center, center);
                flex-grow: unset;
                z-index: 98;
            }

            img {
                height: 50vh;
                // bottom: 0;
                // right: 0;
                position: absolute;
                top: 20%; /* 뷰포트 상단에서 50% 위치 */
                left: 10%; /* 뷰포트 왼쪽에서 50% 위치 */
                transform: scale(0) rotate(65deg);
                transition: transform 0.5s
                    cubic-bezier(0.175, 0.885, 0.32, 1.275);

                @include tablet {
                    height: 100%;
                    position: relative;
                }
            }

            .shape {
                $size: 472px;
                height: $size;
                width: $size;
                position: absolute;
                right: 0;
                top: 50%;
                border-radius: 58% 42% 38% 62% / 42% 55% 45% 58%;
                transform: translate(0, -50%);
                transition: border-radius 13s ease;
                animation: border 10s infinite;

                @include tablet {
                    $size: 250px;
                    height: $size;
                    width: $size;
                    right: 50%;
                    transform: translate(50%, -50%);
                }
            }
        }

        &.active &__image > img {
            transform: scale(1) rotate(0);
        }
    }

    &__control {
        @include flex(center, center);
        position: absolute;
        bottom: 50px;
        left: 50%;
        transform: translateX(-50%);

        @include tablet {
            bottom: 20px;
        }

        &__item ~ &__item {
            margin-left: 10px;
        }

        &__item {
            @include flex(center, center);
            font-size: 2rem;
            cursor: pointer;

            &:hover {
                color: $main-color;
            }

            .index {
                font-size: 1.5rem;
            }
        }
    }
}

@keyframes border {
    0% {
        border-radius: 58% 42% 38% 62% / 42% 55% 45% 58%;
    }
    50% {
        border-radius: 31% 69% 59% 41% / 28% 24% 76% 72%;
    }
    100% {
        border-radius: 58% 42% 38% 62% / 42% 55% 45% 58%;
    }
}

@font-face {
    font-family: 'BMJUA_ttf';
    src: url('../../fonts/BMJUA_ttf.ttf') format('truetype'),
         url('../../fonts/BMJUA_ttf.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'laundrygothic_Regular';
    src: url('../../fonts/laundrygothic_Regular.ttf') format('truetype'),
         url('../../fonts/laundrygothic_Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'SUIT-Regular';
    src: url('https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_suit@1.0/SUIT-Regular.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}

// @font-face {
//     font-family: 'SCDream2';
//     src: url('../../fonts/SCDream2.ttf') format('truetype'),
//         url('../../fonts/SCDream2.ttf') format('truetype');

//     font-weight: normal;
//     font-style: normal;
// }

// @font-face {
//     font-family: 'NanumSquareR';
//     src: url('../../fonts/NanumSquareR.ttf') format('truetype'),
//         url('../../fonts/NanumSquareR.ttf') format('truetype');
//     font-weight: normal;
//     font-style: normal;
// }