.file-upload {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10px;
}

#ex_file {
    display: none;
}

.button-container {
    display: flex;
    gap: 10px; /* 버튼 간의 간격 조정 */
}

.file-upload-button {
    display: inline-block;
    padding: 10px 20px;
    background-color: #4d7faa;
    color: white;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-size: 16px;
    text-align: center;
    border: none;
}

.file-upload-button:hover {
    background-color: #3a6a9e;
}

.file-info {
    margin-top: 10px;
    font-size: 14px;
    color: #333;
}

.file-upload-note {
    color: grey;
    margin-top: 10px;
}
