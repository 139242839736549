.section {
    margin-bottom: 30px;

    & > * ~ * {
        margin-top: 20px;
    }

    &__title {
        font-size: 1.8rem;
        text-transform: capitalize;
        text-align: center;
    }
}
